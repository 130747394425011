.cardfooter {
  color: orange;
  text-align: center;
}
.articlefooter {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}
@media only screen and (max-width: 1280px) {
  .cardfooter {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 60px;
    break-inside: avoid;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
