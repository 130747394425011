body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

br {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.apexhalloween {
  font-family: "Nosifer", cursive;
}

.whitemode {
  background: #e4e4e4;
}

.whitemode .navbar.navbar-expand-lg.navbar-dark.bg-dark {
  background: #857f7a !important;
}

video {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
  background: #f3dcbd;
  background: radial-gradient(circle, #c28b26 15%, #6b3a7f 65%);
}

.offcanvas {
  background: #6b3a7f;
  background: radial-gradient(circle, #ffa500 0%, #6b3a7f 100%);
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

/* Style the scrollbar track */
::-webkit-scrollbar {
  width: 10px;
  /* Adjust the width as needed */
  background-color: #212529;
  /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #c28b26;
  border-radius: 10px;
}

::-webkit-scrollbar:hover {
  background-color: #212529;
}
